import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


interface Resort {
  id: string;
  name: string;
  country: number;
  city: string;
  slugName: string;
  imageId: string;
}

function App() {
  const [campings, setCampings] = useState<Resort[]>([]);

  const getAndUpdateCampings = async () => {
    const campingReq = await fetch("/wp-json/hollistay/v1/proxy");
    const campingData = await campingReq.json();

    const data = campingData.data as Resort[];

    const shuffled = data
      .map(value => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);

    setCampings(shuffled);
  };

  useEffect(() => {
    getAndUpdateCampings();
  }, []);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    arrows:true,
  };

  return campings.length > 0 ? (
    <div className="App p-5">
      <span className='text-xxs text-center m-0 block w-full'>ANNONS</span>
      <Slider {...sliderSettings}>
        {campings.map((camp) => (
          <div key={camp.id} className='h-80 w-full relative overflow-hidden'>
            <a href={`https://www.hollistay.com/Sweden/${camp.city}/${camp.slugName}`} target="_blank" rel="noopener noreferrer">
              <img src={`https://res.cloudinary.com/hollistay-ab/image/upload/w_1000,h_400,c_fill,q_auto/${camp.imageId}.jpg`} alt={camp.name} className='min-w-full min-h-full object-cover object-center'/>
              <caption className='absolute text-white bg-black bottom-0 left-0 p-2 bg-opacity-60'>{camp.name}</caption>
            </a>
          </div>
        ))}
      </Slider>
    </div>
  ): null;
}

export default App;